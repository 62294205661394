$background-color_1: #e3e9ff;

div.bands-container {
    .bands-header {
        margin-bottom: 16px;
        display: flex;
        flex-wrap: wrap;
    }

    .navigate {
        color: #1976d2;
        padding: 0 16px;
        // push calendar button to end
        // margin-left: auto;
        // margin-right: 10vw;

        .MuiTypography-subtitle1 {
            padding-top: 2px;
            padding-left: 2px;
        }

        &.songs {
            color: #444444;
            padding: 0 32px;

            .MuiTypography-inherit {
                padding-top: 2px;
                padding-right: 2px;
            }
        }
    }
    
    .bands-details {

        .band {
            margin: 8px 12px;

            .description {
                padding: 8px;
            }

            .link.expand {
                display: block;
                color: #1c7ed6;
                font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
                font-size: 0.8rem;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .content {
            margin: 0;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5;
        }
    }

    .band-calendar {
        // max-width: 1200px;

        .header {
            background-color: $background-color_1;
            margin: 0;
        }

        .calendar {
            height: 600px;
        }

        .actions {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
}

#bandCalendar {
    scroll-margin-top: 70px;
}