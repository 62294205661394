// Apply these Styles to all pages
body {
    background-color: #4c4d4e2d;
}

div.container {
    margin: 3vh 2vw;
}

div.wrapper {
    margin-bottom: 8px;
}

.content {
	text-align: justify;
}

.header.bold {
    font-weight: 500;
    &.extra { 
        font-weight: 600;
    }
}

// Header Styles
header.app-header {
    background-color: rgb(23, 36, 94);

    .navButton {
        text-decoration: underline;
        color: white;

        &.active {
            text-decoration: none;
            color: rgb(165, 183, 241);
        }
    }
}

//Drawer Navigation styles
div.nav-menu-options {
    background-color: rgb(60, 65, 67);
    padding: 8px 0;

    .navButton {
        display: block;
        text-align: right;
        padding: 6px 24px;
        text-decoration: underline;
        color: white;
        
        &.active {
                text-decoration: none;
                color: rgb(85, 109, 189);
            }
    }
}