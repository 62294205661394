div.home-container {
    .header {
        text-align: center;
        margin: 8px 0;
    }
    
    .drum-picture {
        max-width: 95%;
        margin: auto;
        display: block;
        box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
            0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
        border-radius: 32px;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .navButton {
        text-decoration: underline;
    }

    .content {
        padding-bottom: 8px;
    }

    .wrap {
        padding-top: 8px;
    }
}