div.contact-container {
    .header-container {
        display: flex;
        flex-direction: column;
        text-align: center;

        .header {
            font-weight: 500;
        }

        .contact-details {
            max-width: 600px;
            align-self: center;
            text-align: justify;
            padding: 0 16px;
            &.tips {
                max-width: none;
                margin-bottom: 8px;
            }
        }

        .contact-info {
            h6 {
                display: inline;
            }

            .link {
                display: inline-block;
            }
        }

        .navButton {
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .contact-form-container {
        margin-top: 8px;
        
        .alert-container {
            margin-bottom: 20px;
            justify-content: center;
        }

        .MuiOutlinedInput-root {
            background: rgb(255, 255, 255, 0.5);
        }
    }

    .tip-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 24px;
        .link {
            margin: auto;
            margin-top: 16px;
            button {
                min-width: 200px;
            }
            .MuiTypography-subtitle2 {
                    margin-left: 8px;
                }
        }
    }
}